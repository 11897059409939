import React, { Fragment, useState, useEffect } from "react";
import { useAsync } from "react-async";
import CalendarChart from "./calendarChart";
import { API_AnnualStats } from "../../Api/annualStats";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import LinearProgress from "@material-ui/core/LinearProgress";
import IconButton from "@material-ui/core/IconButton";
import RefreshIcon from "@material-ui/icons/Refresh";
import SpaceBarIcon from "@material-ui/icons/SpaceBar";
import Crop169Icon from "@material-ui/icons/Crop169";
import AllInclusiveIcon from "@material-ui/icons/AllInclusive";
import Tooltip from "@material-ui/core/Tooltip";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  chartCardTitle: {
    padding: "15px 0",
    fontWeight: "bold"
  }
});

const AnnualStats = props => {
  const classes = useStyles();

  const [device, setDevice] = useState({
    device_type: "all",
    all: true,
    wall: false,
    table: false
  });

  const { data, error, isLoading, run } = useAsync({
    promiseFn: API_AnnualStats.promise,
    deferFn: API_AnnualStats.deferred
  });

  return (
    <Fragment>
      <Typography className={classes.chartCardTitle}>
        Which days your customers enjoy the most?
      </Typography>
      <Card>
        <CardContent style={{ height: 400, padding: 0 }} id="CalendarChart">
          {data && data.body.length > 0 ? (
            <CalendarChart
              data={data}
              show={device.device_type}
              isLoading={isLoading}
            />
          ) : null}
        </CardContent>
        <CardActions disableSpacing>
          <Tooltip title="ALL DEVICES">
            <IconButton
              onClick={() =>
                setDevice({
                  device_type: "all",
                  all: true,
                  wall: false,
                  table: false
                })
              }
            >
              <AllInclusiveIcon color={device.all ? "secondary" : "inherit"} />
            </IconButton>
          </Tooltip>
          <Tooltip title="TABLES">
            <IconButton
              onClick={() =>
                setDevice({
                  device_type: "table",
                  all: false,
                  wall: false,
                  table: true
                })
              }
            >
              <SpaceBarIcon
                color={device.table ? "secondary" : "inherit"}
                style={{ transform: "rotate(180deg)" }}
              />
            </IconButton>
          </Tooltip>
          <Tooltip title="WALLS">
            <IconButton
              onClick={() =>
                setDevice({
                  device_type: "wall",
                  all: false,
                  wall: true,
                  table: false
                })
              }
            >
              <Crop169Icon color={device.wall ? "secondary" : "inherit"} />
            </IconButton>
          </Tooltip>
          <Tooltip title="REFRESH">
            <IconButton
              style={{ marginLeft: "auto" }}
              onClick={() => {
                run();
              }}
            >
              <RefreshIcon />
            </IconButton>
          </Tooltip>
        </CardActions>
        {isLoading ? <LinearProgress /> : null}
      </Card>
    </Fragment>
  );
};

export default AnnualStats;
