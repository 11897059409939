import React, { useState, useEffect, Fragment } from "react";
import GenerateDateFormat from "../../helpers/generateDateFormat";
import Typography from "@material-ui/core/Typography";
import Popover from "@material-ui/core/Popover";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListSubheader from "@material-ui/core/ListSubheader";
import Divider from "@material-ui/core/Divider";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import DayPicker, { DateUtils } from "react-day-picker";
import "react-day-picker/lib/style.css";
import uuid from "uuid";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles({
  root: {
    fontSize: ".75rem",
    flexDirection: "column",
    textAlign: "left",
    alignItems: "flex-start"
  },
  menuHead: {
    opacity: "1 !important",
    fontWeight: "bold",
    fontSize: ".8rem !important",
    lineHeight: "1rem",
    paddingTop: "10px",
    paddingBottom: "10px"
  },
  menuCustom: {
    opacity: "1 !important",
    fontWeight: "bold",
    borderTop: "1px solid #ccc",
    fontSize: ".8rem !important"
  },
  headTitle: {
    fontSize: ".7rem",
    fontWeight: "bold",
    color: "#666"
  },
  menuButton: {
    fontSize: ".75rem",
    cursor: "pointer",
    lineHeight: "2rem",
    marginLeft: "10px"
  },
  MuiListItemRoot: {
    paddingTop: "5px",
    paddingBottom: " 5px"
  },
  dateRangeBtn: {
    fontSize: "11px"
  }
});

const refreshPosition = actions => {
  window._popoverActions = actions;
};

const MenuDate = props => {
  const { onDateChange } = props;
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);
  const [range, setRange] = useState({
    from: moment()
      .subtract(7, "days")
      .toDate(),
    to: moment()
      .subtract(1, "days")
      .toDate(),
    fromVs: moment()
      .subtract(14, "days")
      .toDate()
  });

  const [newRange, setNewRange] = useState({ ...range });
  const [showCalendar, setShowCalendar] = useState(false);
  const [selectedDate, setSelectedDate] = useState({
    label: "Last 7 days",
    days: 7
  });

  const modifiers = { start: range.from, end: range.to };

  const handleDayClick = (day, modifiers = {}) => {
    if (modifiers.disabled) {
      return;
    }
    const newRange = DateUtils.addDayToRange(day, range);
    setRange(newRange);
  };

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (label, days) => {
    setShowCalendar(false);
    setAnchorEl(null);
  };

  const handlePresetDateRange = (label, days) => {
    setAnchorEl(null);
    let yesterday = moment()
      .subtract(1, "days")
      .toDate();
    let tempRange = {
      from: moment(yesterday)
        .subtract(days, "days")
        .toDate(),
      to: yesterday,
      fromVs: moment(yesterday)
        .subtract(days * 2 + 1, "days")
        .toDate()
    };
    setSelectedDate({ label: label, days: days });
    setRange(tempRange);
    setNewRange(tempRange);
  };

  const handleCustomDateRange = () => {
    setShowCalendar(false);
    setAnchorEl(null);
    let daysDiff = moment(range.to).diff(moment(range.from)) / 86400000 + 1;
    let tempRange = {
      from: range.from,
      to: range.to,
      fromVs: moment(range.to)
        .subtract(daysDiff * 2 - 1, "days")
        .toDate()
    };
    setSelectedDate({ label: "Custom" });
    setNewRange(tempRange);
  };

  useEffect(() => {
    onDateChange(newRange);
  }, [newRange]);

  return (
    <Fragment>
      <Typography className={classes.menuButton} onClick={handleClick}>
        <GenerateDateFormat
          dateFrom={moment(range.from)
            .format("YYYY-MM-DD")
            .toString()}
          dateTo={range.to}
        />{" "}
        <ArrowDropDownIcon></ArrowDropDownIcon>
      </Typography>
      <Popover
        className={classes.menuList}
        action={refreshPosition}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => handleClose(selectedDate.label, selectedDate.days)}
        modifiers={{
          flip: {
            enabled: false
          }
        }}
      >
        <List
          component="nav"
          subheader={
            <ListSubheader
              component="div"
              id="nested-list-subheader"
              className={`${classes.root} ${classes.menuHead}`}
            >
              <Typography className={classes.headTitle}>
                {selectedDate.label.toUpperCase()}
              </Typography>
              <GenerateDateFormat
                dateFrom={moment(range.from)
                  .format("YYYY-MM-DD")
                  .toString()}
                dateTo={range.to}
              />
            </ListSubheader>
          }
          className={classes.root}
        >
          <Divider></Divider>
          {!showCalendar ? (
            [
              <ListItem
                button
                key={uuid()}
                className={classes.MuiListItemRoot}
                onClick={() => handlePresetDateRange("Last 7 days", 6)}
              >
                Last 7 days
              </ListItem>,
              <ListItem
                button
                key={uuid()}
                className={classes.MuiListItemRoot}
                onClick={() => handlePresetDateRange("Last 14 days", 13)}
              >
                Last 14 days
              </ListItem>,
              <ListItem
                button
                key={uuid()}
                className={classes.MuiListItemRoot}
                onClick={() => handlePresetDateRange("Last 28 days", 27)}
              >
                Last 28 days
              </ListItem>,
              <ListItem
                button
                key={uuid()}
                className={classes.MuiListItemRoot}
                onClick={() => handlePresetDateRange("Last 60 days", 59)}
              >
                Last 60 days
              </ListItem>,
              <ListItem
                button
                key={uuid()}
                className={classes.MuiListItemRoot}
                onClick={() => handlePresetDateRange("Last 90 days", 89)}
              >
                Last 90 days
              </ListItem>,
              <Divider key={uuid()} />,
              <ListItem
                button
                key={uuid()}
                className={`${classes.MuiListItemRoot}`}
                onClick={event => {
                  setShowCalendar(true);
                  window._popoverActions.updatePosition();
                }}
              >
                <strong>Custom date range</strong>
              </ListItem>
            ]
          ) : (
            <ListItem>
              <div>
                <DayPicker
                  className="Selectable"
                  numberOfMonths={1}
                  selectedDays={[range.from, range]}
                  modifiers={modifiers}
                  onDayClick={handleDayClick}
                  disabledDays={{ after: new Date() }}
                />
                <div style={{ width: "100%", textAlign: "right" }}>
                  <Button
                    className={classes.dateRangeBtn}
                    onClick={() =>
                      handleClose("Custom Date Range", selectedDate.days)
                    }
                  >
                    Cancel
                  </Button>
                  <Button
                    className={classes.dateRangeBtn}
                    onClick={() => handleCustomDateRange()}
                    color="primary"
                  >
                    Apply
                  </Button>
                </div>
              </div>
            </ListItem>
          )}
        </List>
      </Popover>
    </Fragment>
  );
};

export default MenuDate;
