import React from "react";
import { useAuth } from "../context/auth";
// import { Redirect } from "react-router-dom";
import DeviceType from "../components/DeviceType";
import Games from "../components/Games";
import HeatMap from "../components/HeatMap";
import AnnualStats from "../components/AnnualStats";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import SelectNation from "../components/SelectNation";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  appBar: {
    backgroundColor: "#fff"
  },
  logo: {
    marginRight: theme.spacing(2),
    height: "40px"
  },
  logoTW: {
    marginRight: theme.spacing(2),
    height: "20px"
  },
  title: {
    textTransform: "uppercase",
    color: "#000",
    flexGrow: 1,
    fontSize: "16px",
    fontWeight: "normal"
  },
  logout: {
    color: "#333"
  },
  countryCodeSelect: {
    "&::before": {
      border: 0
    },
    fontSize: "14px"
  }
}));

const Dashboard = props => {
  const classes = useStyles();
  const { isAuthenticated, isAdmin, countryCode } = useAuth();
  const { handleCountryCode } = props;

  function logOut() {
    sessionStorage.clear();
    window.location.reload();
  }

  return (
    <Container>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <AppBar
            position="static"
            color={"primary"}
            className={classes.appBar}
          >
            <Toolbar>
              <img
                className={classes.logo}
                src="/mcd_logo.svg"
                alt="McDonald's Games Analytics"
              />
              <Typography variant="h6" className={classes.title}>
                McDonald's Games Analytics
              </Typography>
              {isAdmin > 0 ? (
                <SelectNation handleCountryCode={handleCountryCode} />
              ) : null}
              <Button
                onClick={logOut}
                color="inherit"
                className={classes.logout}
              >
                Logout
              </Button>
            </Toolbar>
          </AppBar>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={12}>
          <Games />
        </Grid>
        <Grid item xs={12} lg={6}>
          <HeatMap />
        </Grid>
        <Grid item xs={12} lg={6}>
          <DeviceType />
        </Grid>
        <Grid item xs={12} lg={12}>
          <AnnualStats />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12}></Grid>
        <Grid item xs={12}></Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <AppBar
            position="static"
            color={"primary"}
            className={classes.appBar}
          >
            <Toolbar>
              <img
                className={classes.logoTW}
                src="/logo_touchwindow_big.svg"
                alt="Touchwindow"
              />
              <Typography variant="h6" className={classes.title}></Typography>
              <Link
                href="https://www.touchwindow.it"
                target="_blank"
                rel="noopener"
              >
                www.touchwindow.it
              </Link>
            </Toolbar>
          </AppBar>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Dashboard;

const NavBar = props => {
  const { isAdmin, logOut } = props;
  return (
    <ul>
      <li>Dashboard Page</li>
      <li>{sessionStorage.getItem("isAuthenticated")}</li>
      {isAdmin ? <li>{sessionStorage.getItem("isAdmin")}</li> : null}
      <li>{sessionStorage.getItem("countryCode")}</li>
      <li>
        <button onClick={logOut}>Log out</button>
      </li>
    </ul>
  );
};
