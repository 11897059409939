import React, { useState, useEffect } from "react";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import * as API from "../../constants/api";
import { TokenGenerator } from "../../lib/TokenGenerator";
import { useAuth } from "../../context/auth";

const useStyles = makeStyles(theme => ({
  countryCodeSelect: {
    "&::before": {
      border: 0
    },
    fontSize: "14px"
  }
}));

const SelectNation = props => {
  const classes = useStyles();
  const [nations, setNations] = useState([]);
  const { isAuthenticated, isAdmin, countryCode } = useAuth();
  const { handleCountryCode } = props;
  
  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    fetch(API.NATIONS_API, {
      signal: signal,
      headers: new Headers({ tokenHeader: TokenGenerator() })
    })
      .then(results => results.json())
      .then(data => {
        setNations([...data]);
      });
    return () => {
      abortController.abort();
    };
  }, []);

  return (
    <FormControl className={classes.formControl}>
      <Select
        value={countryCode}
        onChange={handleCountryCode}
        className={classes.countryCodeSelect}
      >
        {nations.map(nation => {
          return (
            <MenuItem value={nation.countryCode} key={nation.countryCode}>
              {nation.nationName.toUpperCase()}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default SelectNation;
