import { ANNUALSTATS_API } from "../constants/api";
import { TokenGenerator } from "../lib/TokenGenerator";
export const API_PROMISE_AnnualStats = async () =>
  await fetch(ANNUALSTATS_API + "/" + sessionStorage.getItem("countryCode"), {
    headers: new Headers({ tokenHeader: TokenGenerator() })
  })
    .then(res => (res.ok ? res : Promise.reject(res)))
    .then(res => res.json());

export const API_DEFERRED_AnnualStats = async () =>
  await fetch(ANNUALSTATS_API + "/" + sessionStorage.getItem("countryCode"), {
    headers: new Headers({ tokenHeader: TokenGenerator() })
  })
    .then(res => (res.ok ? res : Promise.reject(res)))
    .then(res => res.json());

export const API_AnnualStats = {
  promise: async () =>
    await fetch(ANNUALSTATS_API + "/" + sessionStorage.getItem("countryCode"), {
      headers: new Headers({ tokenHeader: TokenGenerator() })
    })
      .then(res => (res.ok ? res : Promise.reject(res)))
      .then(res => res.json()),
  deferred: async () =>
    await fetch(ANNUALSTATS_API + "/" + sessionStorage.getItem("countryCode"), {
      headers: new Headers({ tokenHeader: TokenGenerator() })
    })
      .then(res => (res.ok ? res : Promise.reject(res)))
      .then(res => res.json())
};
