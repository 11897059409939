import React from "react";
import GenerateVsDateFormat from "../../helpers/generateVsDateFormat";
import calcTrend from "../../helpers/calcTrend";
import { makeStyles } from "@material-ui/core/styles";
import { useAuth } from "../../context/auth";
import HoursAndMinutes from "../../lib/HoursAndMinutes";

const useStyles = makeStyles({
  tooltipTable: {
    fontSize: ".8rem"
  },
  label: {
    fontSize: ".7rem"
  },
  value: {
    fontWeight: 900
  }
});
const GameTooltip = props => {
  const { dateFrom, dateTo, indexValue, data } = props;
  const classes = useStyles();
  const { isAuthenticated, isAdmin, countryCode } = useAuth();

  const roundDecimal = num => {
    return Math.round(num * 100) / 100;
  };

  return (
    <table className={classes.tooltipTable}>
      <tbody>
        <tr>
          <td colSpan="3">
            <GenerateVsDateFormat dateFrom={dateFrom} dateTo={dateTo} />
          </td>
        </tr>
        <tr>
          <td colSpan="3">
            <strong>{indexValue}</strong>
          </td>
        </tr>
        <tr>
          <td className={classes.label}>TOT. TIME PLAYED:</td>
          <td className={classes.value}>
            {HoursAndMinutes(data.tot_duration)}
          </td>
          <td>{calcTrend(data.tot_duration, data.prev_tot_duration)}</td>
        </tr>
        <tr>
          <td className={classes.label}>SESSION:</td>
          <td className={classes.value}>{data.tot_session}</td>
          <td>{calcTrend(data.tot_session, data.prev_tot_session)}</td>
        </tr>
        <tr>
          <td className={classes.label}>SKIPPED:</td>
          <td className={classes.value}>{data.tot_skipped}</td>
          <td>{calcTrend(data.tot_skipped, data.prev_tot_skipped)}</td>
        </tr>
        {isAdmin > 0 ? (
          <tr>
            <td className={classes.label}>AVG. SESSION DURATION:</td>
            <td className={classes.value}>
              {HoursAndMinutes(data.tot_duration / data.tot_session)}
            </td>
            <td>
              {calcTrend(
                data.tot_duration / data.tot_session,
                data.prev_tot_duration / data.prev_tot_session
              )}
            </td>
          </tr>
        ) : null}
      </tbody>
    </table>
  );
};

export default GameTooltip;
