import React from "react";
import { ResponsivePie } from "@nivo/pie";

const DeviceTypeChart = props => {
  const { data } = props;
  let resultData = [];

  data.body.map((deviceType, index) => {
    resultData.push({
      id: deviceType.SystemTypeName,
      label: deviceType.SystemTypeName,
      value: deviceType.DevNum
    });
  });

  return (
    <ResponsivePie
      data={resultData}
      margin={{ top: 0, right: 80, bottom: 30, left: 40 }}
      startAngle={-90}
      endAngle={90}
      innerRadius={0.55}
      padAngle={3}
      cornerRadius={0}
      colors={{ scheme: "paired" }}
      borderWidth={1}
      borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
      radialLabelsSkipAngle={10}
      radialLabelsTextXOffset={6}
      radialLabelsTextColor="#333333"
      radialLabelsLinkOffset={0}
      radialLabelsLinkDiagonalLength={16}
      radialLabelsLinkHorizontalLength={24}
      radialLabelsLinkStrokeWidth={1}
      radialLabelsLinkColor={{ from: "color" }}
      slicesLabelsSkipAngle={10}
      slicesLabelsTextColor="#333333"
      animate={true}
      motionStiffness={90}
      motionDamping={15}
      legends={[
        {
          anchor: "bottom",
          direction: "row",
          itemDirection: "top-to-bottom",
          translateY: 0,
          itemWidth: 50,
          itemHeight: 18,
          itemTextColor: "#999",
          symbolSize: 20,
          symbolShape: "circle",
          effects: [
            {
              on: "hover",
              style: {
                itemTextColor: "#000"
              }
            }
          ]
        }
      ]}
    />
  );
};

export default DeviceTypeChart;
