import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import { useAuth } from "../context/auth";
import axios from "axios";
import { AUTH_API, UPDATE_LOGIN_DATE_API } from "../constants/api.js";
import { TokenGenerator } from "../lib/TokenGenerator";
import moment from "moment";
axios.defaults.headers.common["tokenHeader"] = TokenGenerator();

const useStyles = makeStyles({
  card: {
    maxWidth: 345
  },
  media: {
    height: 140,
    marginTop: 30,
    backgroundSize: "contain"
  },
  textField: {
    width: "100%"
  },
  grid: {
    height: "100%"
  },
  container: {
    height: "100%"
  }
});

function Login(props) {
  const [isError, setIsError] = useState(false);
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const { isAuthenticated, setSession } = useAuth();
  const classes = useStyles();

  function postLogin() {
    setLoading(true);

    axios
      .post(AUTH_API, {
        user: userName,
        password: password
      })
      .then(result => {
        setLoading(false);
        if (result.status === 200) {
          axios.post(UPDATE_LOGIN_DATE_API, {
            userId: result.data.body.id,
            date: moment()
              .format("ddd MMM DD YYYY HH:mm:ss")
              .toString(),
            gmt: moment()
              .format("ZZ")
              .toString()
          });
          setSession(result.data.body);
        } else {
          setIsError(true);
        }
      })
      .catch(e => {
        setLoading(false);
        setIsError(true);
      });
  }

  if (isAuthenticated) {
    console.log("islogged");
    return <Redirect to="/dashboard" />;
  }

  return (
    <Container className={classes.container}>
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        className={classes.grid}
      >
        <Card className={classes.card}>
          <CardMedia
            className={classes.media}
            image="/logo.svg"
            title="McDonald's Games Analytics"
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2">
              McDonald's Games Analytics
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              Enter your credential to access
            </Typography>
            {isError && (
              <Typography variant="body2" color="error" component="p">
                The username or password provided were incorrect!
              </Typography>
            )}
            <TextField
              required
              id="username"
              label="Username"
              defaultValue=""
              className={classes.textField}
              margin="normal"
              onChange={e => {
                setUserName(e.target.value);
              }}
            />
            <TextField
              required
              id="password"
              label="Password"
              defaultValue=""
              type="password"
              className={classes.textField}
              margin="normal"
              onChange={e => {
                setPassword(e.target.value);
              }}
            />
          </CardContent>
          <CardActions>
            <Button size="small" color="primary" onClick={postLogin}>
              Login
            </Button>
          </CardActions>
          {loading ? <LinearProgress /> : null}
        </Card>
      </Grid>
    </Container>
  );
}

export default Login;
