import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useAuth } from "./context/auth";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { isAuthenticated } = useAuth();
  let isAuthenticated_session = sessionStorage.getItem("isAuthenticated");

  return (
    <Route
      {...rest}
      render={props =>
        isAuthenticated || isAuthenticated_session ? (
          <Component {...props} />
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
};

export default PrivateRoute;
