import React from "react";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  dateRange: { color: "#000", display: "inline-block" },
  dateFrom: { color: "#000" },
  vs: { color: "#2196f3" },
  dateTo: {}
});

const GenerateDateFormat = ({ dateFrom, dateTo, dateFormat }) => {
  const classes = useStyles();

  if (!dateFormat) {
    dateFormat = "DD MMM YYYY";
  }

  const rangeDateFormat = (from, to) => {
    let date = "";
    if (moment(from).month() === moment(to).month()) {
      if (moment(from).year() === moment(to).year()) {
        if (moment(from).date() === moment(to).date()) {
          date += moment(to).format(dateFormat);
        } else {
          date +=
            moment(from).format("DD") + " - " + moment(to).format(dateFormat);
        }
      } else {
        date +=
          moment(from).format(dateFormat) +
          " - " +
          moment(to).format(dateFormat);
      }
    } else {
      if (moment(from).year() === moment(to).year()) {
        date +=
          moment(from).format("DD MMM") + " - " + moment(to).format(dateFormat);
      } else {
        date +=
          moment(from).format(dateFormat) +
          " - " +
          moment(to).format(dateFormat);
      }
    }

    return date;
  };

  return (
    <span className={classes.dateRange}>
      <span className={classes.dateFrom}>
        {rangeDateFormat(dateFrom, dateTo)}
      </span>
    </span>
  );
};

export default GenerateDateFormat;
