import React, { Fragment, useState, useEffect } from "react";
import { useAsync } from "react-async";
import moment from "moment";
import GamesChart from "./gamesChart";
import { API_GamesByDay } from "../../Api/gameByDay";
import MenuDate from "../MenuDate";
import TabPanel from "../TabPanel";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import LinearProgress from "@material-ui/core/LinearProgress";
import IconButton from "@material-ui/core/IconButton";
import RefreshIcon from "@material-ui/icons/Refresh";
import SpaceBarIcon from "@material-ui/icons/SpaceBar";
import Crop169Icon from "@material-ui/icons/Crop169";
import AllInclusiveIcon from "@material-ui/icons/AllInclusive";
import Tooltip from "@material-ui/core/Tooltip";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  chartCardTitle: {
    padding: "15px 0",
    fontWeight: "bold"
  }
});

const Games = props => {

  const classes = useStyles();

  const [view, setView] = useState(0);

  const [device, setDevice] = useState({
    device_type: "all",
    all: true,
    wall: false,
    table: false
  });

  const [range, setRange] = useState({
    from: moment()
      .subtract(7, "days")
      .toDate(),
    to: moment()
      .subtract(1, "days")
      .toDate(),
    fromVs: moment()
      .subtract(14, "days")
      .toDate()
  });

  useEffect(() => {
      run(
        moment(range.fromVs)
          .format("YYYY-MM-DD")
          .toString(),
        moment(range.to)
          .format("YYYY-MM-DD")
          .toString()
      );
  }, [range]);

  const { data, error, isLoading, run } = useAsync({
    //  promiseFn: API_GamesByDay.promise,
    deferFn: API_GamesByDay.deferred,
    dateFrom: moment(range.fromVs)
      .format("YYYY-MM-DD")
      .toString(),
    dateTo: moment(range.to)
      .format("YYYY-MM-DD")
      .toString()
  });

  const handleChangeView = (event, newValue) => {
    setView(newValue);
  };

  const onDateChange = range => {
    setRange(range);
  };

  return (
    <Fragment>
      <Typography className={classes.chartCardTitle}>
        Which games your customers enjoy the most?
      </Typography>
      <Card>
        {data && data.body.length > 0 ? (
          <TabPanel
            data={data}
            view={view}
            range={range}
            handleChangeView={handleChangeView}
            isLoading={isLoading}
          ></TabPanel>
        ) : null}
        <CardContent style={{ height: 400, padding: 0 }} id="GamesChart">
          {data && data.body.length > 0 ? (
            <GamesChart
              data={data}
              view={view}
              dateFrom={range.from}
              dateTo={range.to}
              show={device.device_type}
              isLoading={isLoading}
            />
          ) : null}
        </CardContent>
        <CardActions disableSpacing>
          <Tooltip title="ALL DEVICES">
            <IconButton
              onClick={() =>
                setDevice({
                  device_type: "all",
                  all: true,
                  wall: false,
                  table: false
                })
              }
            >
              <AllInclusiveIcon color={device.all ? "secondary" : "inherit"} />
            </IconButton>
          </Tooltip>
          <Tooltip title="TABLES">
            <IconButton
              onClick={() =>
                setDevice({
                  device_type: "table",
                  all: false,
                  wall: false,
                  table: true
                })
              }
            >
              <SpaceBarIcon
                color={device.table ? "secondary" : "inherit"}
                style={{ transform: "rotate(180deg)" }}
              />
            </IconButton>
          </Tooltip>
          <Tooltip title="WALLS">
            <IconButton
              onClick={() =>
                setDevice({
                  device_type: "wall",
                  all: false,
                  wall: true,
                  table: false
                })
              }
            >
              <Crop169Icon color={device.wall ? "secondary" : "inherit"} />
            </IconButton>
          </Tooltip>
          <MenuDate onDateChange={days => onDateChange(days)} p={"GAMES CHART MENU DATE"}/>
          <Tooltip title="REFRESH">
            <IconButton
              style={{ marginLeft: "auto" }}
              onClick={() => {
                run(
                  moment(range.fromVs)
                    .format("YYYY-MM-DD")
                    .toString(),
                  moment(range.to)
                    .format("YYYY-MM-DD")
                    .toString()
                );
              }}
            >
              <RefreshIcon />
            </IconButton>
          </Tooltip>
        </CardActions>
        {isLoading ? <LinearProgress /> : null}
      </Card>
    </Fragment>
  );
};
export default Games;
