import React from "react";
import { ResponsiveCalendar } from "@nivo/calendar";
import moment from "moment";
import DayTooltip from "../GraphTooltip/dayTooltip";

const CalendarChart = props => {
  const { data, show, isLoading } = props;
  let resultDataObj = {};
  let resultData = [];
  let resultDataTotal = [];
  let resultDataTable = [];
  let resultDataWall = [];
  let schema = { day: "", value: 0, tot_dev:0, tot_dur: 0, tot_ses: 0, tot_skip: 0 };

  let fromDate = new Date().getFullYear() + "-01-01";

  const populateData = data => {
    let fetchedData = { ...schema };
    fetchedData.day = data._id.date;
    fetchedData.value = Math.round(data.tot_dur);
    fetchedData.tot_dev = Math.round(data.tot_dev);
    return fetchedData;
  };

  const createTooltip = ({
    color,
    theme,
    id,
    value,
    index,
    indexValue,
    data
  }) => {
    return <DayTooltip indexValue={indexValue} data={data} />;
  };

  data.body.map(day => {
    if (resultDataObj[day._id.date]) {
      resultDataObj[day._id.date].value += Math.round(day.tot_dur);
      resultDataObj[day._id.date].tot_dev += Math.round(day.tot_dev);
    } else {
      resultDataObj[day._id.date] = {value:0,tot_dev:0}
      resultDataObj[day._id.date].value = Math.round(day.tot_dur);
      resultDataObj[day._id.date].tot_dev = Math.round(day.tot_dev);
    }
    switch (day._id.device_type) {
      case "Table":
        resultDataTable.push(populateData(day));
        break;
      case "Wall":
        resultDataWall.push(populateData(day));
        break;
      default:
        break;
    }
  });

  Object.keys(resultDataObj).map((key, index) => {
    let fetchedData = { ...schema };
    fetchedData.day = key;
    fetchedData.value = resultDataObj[key].value;
    fetchedData.tot_dev = resultDataObj[key].tot_dev;
    resultDataTotal.push(fetchedData);
  });

  switch (show) {
    case "table":
      resultData = resultDataTable;
      break;
    case "wall":
      resultData = resultDataWall;
      break;
    case "all":
      resultData = resultDataTotal;
      break;
    default:
      resultData = resultDataTotal;
      break;
  }

  return (
    <ResponsiveCalendar
      data={resultData}
      from={fromDate}
      to={moment()
        .format("YYYY-MM-DD")
        .toString()}
      emptyColor="#eeeeee"
      colors={[
        "#e3f2fd",
        "#90caf9",
        "#42a5f5",
        "#1e88e5",
        "#1565c0",
        "#0d47a1"
      ]}
      margin={{ top: 40, right: 40, bottom: 40, left: 40 }}
      yearSpacing={40}
      monthBorderColor="#ffffff"
      monthBorderWidth={0}
      dayBorderWidth={2}
      dayBorderColor="#ffffff"
      minValue="auto"
      maxValue="auto"
      tooltip={({ color, theme, id, value, index, indexValue, data }) =>
        createTooltip({
          color,
          theme,
          id,
          value,
          index,
          indexValue,
          data
        })
      }
    />
  );
};

export default CalendarChart;
