export const numberToString = num => {
  switch (true) {
    case num >= 1000000:
      return Math.round(num / 100000) / 10 + "M";
    case num >= 10000:
      return Math.round(num / 100) / 10 + "K";
    default:
      return num;
  }
};
