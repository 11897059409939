import React from "react";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  dateRange: { fontSize: "0.7rem", color: "#999", paddingBottom:'10px', display:'inline-block' },
  dateFrom: {color: "#333"},
  vs: {color:"#2196f3"},
  dateTo: { }
});

const GenerateVsDateFormat = ({ dateFrom, dateTo }) => {
  const classes = useStyles();
  let diff = Math.abs(moment(dateFrom).diff(moment(dateTo), "days")) + 1;
  let vs_dateFrom = moment(dateFrom).subtract(diff, "days").format("YYYY-MM-DD");
  let vs_dateTo = moment(dateTo).subtract(diff, "days").format("YYYY-MM-DD");
  const rangeDateFormat = (from, to) => {
    let date = "";
    if (moment(from).month() === moment(to).month()) {
      if (moment(from).year() === moment(to).year()) {
        if (moment(from).date() === moment(to).date()) {
          date += moment(to).format("D MMM YYYY");
        } else {
          date +=
            moment(from).format("D") + " - " + moment(to).format("D MMM YYYY");
        }
      } else {
        date +=
          moment(from).format("D MMM YYYY") +
          " - " +
          moment(to).format("D MMM YYYY");
      }
    } else {
      if (moment(from).year() === moment(to).year()) {
        date +=
          moment(from).format("D MMM") +
          " - " +
          moment(to).format("D MMM YYYY");
      } else {
        date +=
          moment(from).format("D MMM YYYY") +
          " - " +
          moment(to).format("D MMM YYYY");
      }
    }
    
    return date;
  };

  return (
    <span className={classes.dateRange}>
      <span className={classes.dateFrom}>
        {rangeDateFormat(dateFrom, dateTo)}
      </span>
      <span className={classes.vs}> vs </span>
      <span className={classes.dateTo}>
        {rangeDateFormat(vs_dateFrom, vs_dateTo)}
      </span>
    </span>
  );
};

export default GenerateVsDateFormat;
