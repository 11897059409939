import React, { Fragment } from "react";
import { Typography } from "@material-ui/core";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TabReview from "../TabReview";
import moment from "moment";

const TabPanel = props => {
  const { data, view, range, handleChangeView, isLoading } = props;
  let tot_dur = 0;
  let tot_ses = 0;
  let tot_skip = 0;
  let prev_tot_dur = 0;
  let prev_tot_ses = 0;
  let prev_tot_skip = 0;

  if (data) {
    data.body.map(el => {
      if (moment(el._id.date).isSameOrAfter(moment(range.from), "day")) {
        tot_dur += el.tot_dur;
        tot_ses += el.clean_ses
        tot_skip += el.tot_skip;
      } else {
        prev_tot_dur += el.tot_dur;
        prev_tot_ses += el.clean_ses
        prev_tot_skip += el.tot_skip;
      }
      return true;
    });
  }

  return (
    <Fragment>
      <Tabs
        value={view}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleChangeView}
        variant="scrollable"
        scrollButtons="auto"
      >
        <Tab
          label={
            <TabReview
              title="TOT. TIME PLAYED (Hours)"
              description="Discover the customer's total time played for the selected time period."
              curr_value={Math.round(tot_dur / 3600)}
              prev_value={Math.round(prev_tot_dur / 3600)}
              isLoading={isLoading}
            ></TabReview>
          }
        ></Tab>
        <Tab
          label={
            <TabReview
              title="TOTAL SESSIONS"
              description="Find out how many matches your customers have played in the selected time period."
              curr_value={tot_ses}
              prev_value={prev_tot_ses}
              isLoading={isLoading}
            ></TabReview>
          }
        ></Tab>
        <Tab
          label={
            <TabReview
              title="TOTAL SKIPPED"
              description="Find out the total number of matches abandoned before 15 seconds."
              curr_value={tot_skip}
              prev_value={prev_tot_skip}
              isLoading={isLoading}
            ></TabReview>
          }
        ></Tab>
      </Tabs>
    </Fragment>
  );
};

export default TabPanel;
