import React from "react";
import GenerateDateFormat from "../../helpers/generateDateFormat";
import { makeStyles } from "@material-ui/core/styles";
import HoursAndMinutes from '../../lib/HoursAndMinutes'

const useStyles = makeStyles({
  tooltipTable: {
    fontSize: ".8rem"
  },
  label: {
    fontSize: ".7rem"
  },
  value: {
    fontWeight: 900
  }
});

const DayTooltip = props => {
  const { indexValue, data } = props;
  const classes = useStyles();

  return (
    <table className={classes.tooltipTable}>
      <tbody>
        <tr>
          <td colSpan="3">
            <GenerateDateFormat
              dateFrom={data.day}
              dateTo={data.day}
              dateFormat={"ddd DD MMM YYYY"}
            />
          </td>
        </tr>
        <tr>
          <td colSpan="3">
            <strong>{indexValue}</strong>
          </td>
        </tr>
        <tr>
          <td className={classes.label}>TOT. TIME PLAYED :</td>
          <td className={classes.value}>{HoursAndMinutes(data.value)}</td>
        </tr>
        <tr>
          <td className={classes.label}>AVG. TIME PLAYED :</td>
          <td className={classes.value}>{HoursAndMinutes(data.value/data.tot_dev)}</td>
        </tr>
        <tr>
          <td className={classes.label}>DEVICES :</td>
          <td className={classes.value}>{data.tot_dev}</td>
        </tr>
      </tbody>
    </table>
  );
};

export default DayTooltip;
