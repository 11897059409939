import React, { Fragment } from "react";
import Typography from "@material-ui/core/Typography";
import calcTrend from "../../helpers/calcTrend";
import { makeStyles } from "@material-ui/core/styles";
import { numberToString } from "../../helpers/numberToString";

const useStyles = makeStyles({
  TabTitle: {
    textAlign: "center",
    fontSize: ".75rem",
    fontWeight: "bold",
    padding: "10px 0 0"
  },
  TabSubtitle: {
    textAlign: "center",
    fontSize: ".7rem",
    lineHeight:".7rem",
    fontWeight: "normal",
    padding: "0px 0 10px",
    color:"#999",
      textTransform:"none"
  },
  TabTotal: {
    fontWeight: "normal",
    fontSize: "1.5rem"
  }
});

const TabReview = props => {
  const { title, curr_value, prev_value, isLoading, description } = props;
  const classes = useStyles();
  return (
    <Fragment>
      <Typography className={classes.TabTitle}>{title}</Typography>
      <Typography className={classes.TabSubtitle}>{description}</Typography>
      <Typography className={classes.TabTotal}>
        {numberToString(curr_value)}
      </Typography>
      <Typography className={classes.TabTrend}>
        {!isLoading ? calcTrend(curr_value, prev_value) : "---"}
      </Typography>
    </Fragment>
  );
};

export default TabReview;
