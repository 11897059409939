import React from "react";
import { ResponsiveBar } from "@nivo/bar";
import _ from "lodash";
import moment from "moment";
import { numberToString } from "../../helpers/numberToString";
import GameTooltip from "../GraphTooltip/gameTooltip";

const GamesChart = props => {
  const { data, dateFrom, dateTo, show, view, isLoading } = props;
  let resultData = [];
  let resultDataTot = [];
  let resultData_table = [];
  let resultData_wall = [];
  let avgDuration = 0;
  let avgDuration_tot = 0;
  let avgDuration_table = 0;
  let avgDuration_wall = 0;
  let avgSession = 0;
  let avgSession_tot = 0;
  let avgSession_table = 0;
  let avgSession_wall = 0;
  let avgSkipped = 0;
  let avgSkipped_tot = 0;
  let avgSkipped_table = 0;
  let avgSkipped_wall = 0;
  let current_view = "";
  let current_avg = "";
  let current_view_label = "";
  let activeGames = 0;

  const createTooltip = ({
    color,
    theme,
    id,
    value,
    index,
    indexValue,
    data
  }) => {
    return (
      <GameTooltip
        dateFrom={dateFrom}
        dateTo={dateTo}
        indexValue={indexValue}
        data={data}
      />
    );
  };

  const itemTotalSchema = {
    tot_duration: 0,
    tot_session: 0,
    tot_skipped: 0,
    prev_tot_duration: 0,
    prev_tot_session: 0,
    prev_tot_skipped: 0
  };

  data.body.map((item, index) => {
    let resIndex = resultDataTot.findIndex(e => e.game === item._id.game);
    let deviceType = item._id.device_type.toLowerCase();
    let newItem = { ...itemTotalSchema };
    newItem.table = { ...itemTotalSchema };
    newItem.wall = { ...itemTotalSchema };
    let isInRangeDates = false;

    if (moment(item._id.date).isSameOrAfter(moment(dateFrom), "day")) {
      avgDuration_tot += item.tot_dur;
      avgSession_tot += item.clean_ses;
      avgSkipped_tot += item.tot_skip;
      if (deviceType === "table") {
        avgDuration_table += item.tot_dur;
        avgSession_table += item.clean_ses;
        avgSkipped_table += item.tot_skip;
      } else {
        avgDuration_wall += item.tot_dur;
        avgSession_wall += item.clean_ses;
        avgSkipped_wall += item.tot_skip;
      }
      isInRangeDates = true;
    } else {
      isInRangeDates = false;
    }

    if (resIndex < 0) {
      newItem.id = index;
      newItem.game = item._id.game;
      if (isInRangeDates) {
        newItem.tot_duration += item.tot_dur;
        newItem.tot_session += item.clean_ses;
        newItem.tot_skipped += item.tot_skip;
        newItem[deviceType].tot_duration += item.tot_dur;
        newItem[deviceType].tot_session += item.clean_ses;
        newItem[deviceType].tot_skipped += item.tot_skip;
        resultDataTot.push(newItem);
      } else {
        newItem.prev_tot_duration += item.tot_dur;
        newItem.prev_tot_session += item.clean_ses;
        newItem.prev_tot_skipped += item.tot_skip;
        newItem[deviceType].prev_tot_duration += item.tot_dur;
        newItem[deviceType].prev_tot_session += item.clean_ses;
        newItem[deviceType].prev_tot_skipped += item.tot_skip;
        resultDataTot.push(newItem);
      }
    } else {
      if (isInRangeDates) {
        resultDataTot[resIndex].tot_duration += item.tot_dur;
        resultDataTot[resIndex].tot_session += item.clean_ses;
        resultDataTot[resIndex].tot_skipped += item.tot_skip;
        resultDataTot[resIndex][deviceType].tot_duration += item.tot_dur;
        resultDataTot[resIndex][deviceType].tot_session += item.clean_ses;
        resultDataTot[resIndex][deviceType].tot_skipped += item.tot_skip;
      } else {
        resultDataTot[resIndex].prev_tot_duration += item.tot_dur;
        resultDataTot[resIndex].prev_tot_session += item.clean_ses;
        resultDataTot[resIndex].prev_tot_skipped += item.tot_skip;
        resultDataTot[resIndex][deviceType].prev_tot_duration += item.tot_dur;
        resultDataTot[resIndex][deviceType].prev_tot_session += item.clean_ses;
        resultDataTot[resIndex][deviceType].prev_tot_skipped += item.tot_skip;
      }
    }
  });

  resultDataTot.map((game, index) => {
    let newItem_wall = { ...itemTotalSchema };
    let newItem_table = { ...itemTotalSchema };
    newItem_wall.id = game.id;
    newItem_table.id = game.id;
    newItem_wall.game = game.game;
    newItem_table.game = game.game;
    newItem_table = { ...newItem_table, ...game.table };
    newItem_wall = { ...newItem_wall, ...game.wall };
    resultData_wall.push(newItem_wall);
    resultData_table.push(newItem_table);
  });

  const countActiveGames = games => {
    let count = 0;
    games.map(game => {
      if (game.tot_duration > 0) {
        return count++;
      } else {
        return count;
      }
    });
    return count;
  };

  switch (show) {
    case "table":
      resultData = resultData_table;
      activeGames = countActiveGames(resultData);
      avgDuration = Math.round(avgDuration_table / activeGames);
      avgSession = Math.round(avgSession_table / activeGames);
      avgSkipped = Math.round(avgSkipped_table / activeGames);
      break;
    case "wall":
      resultData = resultData_wall;
      activeGames = countActiveGames(resultData);
      avgDuration = Math.round(avgDuration_wall / activeGames);
      avgSession = Math.round(avgSession_wall / activeGames);
      avgSkipped = Math.round(avgSkipped_wall / activeGames);
      break;
    case "all":
      resultData = resultDataTot;
      activeGames = countActiveGames(resultData);
      avgDuration = Math.round(avgDuration_tot / activeGames);
      avgSession = Math.round(avgSession_tot / activeGames);
      avgSkipped = Math.round(avgSkipped_tot / activeGames);
      break;
    default:
      resultData = resultDataTot;
      activeGames = countActiveGames(resultData);
      avgDuration = Math.round(avgDuration_tot / activeGames);
      avgSession = Math.round(avgSession_tot / activeGames);
      avgSkipped = Math.round(avgSkipped_tot / activeGames);
      break;
  }

  switch (view) {
    case 0:
      current_view_label = "Duration (Hours)";
      current_view = "tot_duration";
      current_avg = avgDuration;
      break;
    case 1:
      current_view_label = "Sessions";
      current_view = "tot_session";
      current_avg = avgSession;
      break;
    case 2:
      current_view_label = "Skipped";
      current_view = "tot_skipped";
      current_avg = avgSkipped;
      break;
    default:
      current_view_label = "Duration (Hours)";
      current_view = "tot_duration";
      current_avg = avgDuration;
      break;
  }
  return (
    <ResponsiveBar
      data={_.sortBy(resultData, [current_view])}
      keys={[current_view]}
      indexBy="game"
      margin={{ top: 50, right: 25, bottom: 50, left: 75 }}
      padding={0.3}
      colors={"#1565c0"}
      borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 20,
        legend: "",
        legendPosition: "middle",
        legendOffset: 32
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: current_view_label,
        legendPosition: "middle",
        legendOffset: -50,
        format: value => view === 0 ?  Math.floor(value / 3600) : value
      }}
      tooltip={({ color, theme, id, value, index, indexValue, data }) =>
        createTooltip({
          color,
          theme,
          id,
          value,
          index,
          indexValue,
          data
        })
      }
      label={value => view === 0 ? numberToString(Math.floor(value.value / 3600)) : value.value }
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={"#fff"}
      markers={[
        {
          axis: "y",
          value: current_avg,
          lineStyle: { stroke: "rgba(0, 0, 0, .2)", strokeWidth: 2 },
          legend: "Avg." + (view === 0 ? Math.floor(current_avg / 3600) : current_avg),
          legendOrientation: "vertical"
        }
      ]}
      animate={true}
      motionStiffness={90}
      motionDamping={15}
    />
  );
};

export default GamesChart;
