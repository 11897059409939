import { SHA256 } from "crypto-js";

export const TokenGenerator = () => {
  let actualTime = new Date().toUTCString();
  let timeRegex = /(\d\d):(\d\d)/;
  actualTime = timeRegex.exec(actualTime)[0];
  actualTime = actualTime.split(":");
  let key = actualTime[1] + "|nQp$BNpx7kW4w?Ay=#J-B6,KHgFg/" + actualTime[0];
  return SHA256(key).toString();
};
