import { GAMES_BY_DAY } from "../constants/api";
import { TokenGenerator } from "../lib/TokenGenerator";
export const API_PROMISE_gamesByDay = async ({ dateFrom, dateTo }) =>
  await fetch(
    GAMES_BY_DAY +
      "/" +
      sessionStorage.getItem("countryCode") +
      "/" +
      dateFrom +
      "/" +
      dateTo,
    { headers: new Headers({ tokenHeader: TokenGenerator() }) }
  )
    .then(res => (res.ok ? res : Promise.reject(res)))
    .then(res => res.json());

export const API_DEFERRED_gamesByDay = async ([dateFrom, dateTo]) =>
  await fetch(
    GAMES_BY_DAY +
      "/" +
      sessionStorage.getItem("countryCode") +
      "/" +
      dateFrom +
      "/" +
      dateTo,
    { headers: new Headers({ tokenHeader: TokenGenerator() }) }
  )
    .then(res => (res.ok ? res : Promise.reject(res)))
    .then(res => res.json());

export const API_GamesByDay = {
  promise: async ({ dateFrom, dateTo }) =>
    await fetch(
      GAMES_BY_DAY +
        "/" +
        sessionStorage.getItem("countryCode") +
        "/" +
        dateFrom +
        "/" +
        dateTo,
      { headers: new Headers({ tokenHeader: TokenGenerator() }) }
    )
      .then(res => (res.ok ? res : Promise.reject(res)))
      .then(res => res.json()),
  deferred: async ([dateFrom, dateTo]) =>
    await fetch(
      GAMES_BY_DAY +
        "/" +
        sessionStorage.getItem("countryCode") +
        "/" +
        dateFrom +
        "/" +
        dateTo,
      { headers: new Headers({ tokenHeader: TokenGenerator() }) }
    )
      .then(res => (res.ok ? res : Promise.reject(res)))
      .then(res => res.json())
};
