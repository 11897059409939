import { createContext, useContext } from "react";

const AuthContext = createContext();

export const AuthProvider = AuthContext.Provider;
export const AuthConsumer = AuthContext.Consumer;

export const useAuth = () => {
  return useContext(AuthContext);
};

export default AuthContext;
