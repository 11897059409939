import React, { Fragment, useState } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import PrivateRoute from "./PrivateRoute";
import Login from "./pages/login";
import Dashboard from "./pages/dashboard";
import { AuthProvider } from "./context/auth";

const App = props => {
  const [isAuthenticated, setIsAuthenticated] = useState(0);
  const [isAdmin, setIsAdmin] = useState(0);
  const [countryCode, setCountryCode] = useState("");

  const setSession = data => {
    if (data !== undefined) {
      sessionStorage.setItem("isAuthenticated", 1);
      sessionStorage.setItem("isAdmin", data.IsAdmin);
      sessionStorage.setItem("countryCode", data.CountryCode);
      setIsAuthenticated(true);
      setIsAdmin(data.IsAdmin);
      setCountryCode(data.CountryCode);
    } else {
      sessionStorage.clear();
      setIsAuthenticated(false);
      setIsAdmin(false);
      setCountryCode();
    }
  };

  const handleCountryCode = event => {
    sessionStorage.setItem("countryCode", event.target.value);
    setCountryCode(event.target.value);
  };

  return (
    <Fragment>
      <CssBaseline />
      <AuthProvider
        value={{
          isAuthenticated: sessionStorage.getItem("isAuthenticated"),
          setIsAuthenticated: setIsAuthenticated,
          isAdmin: sessionStorage.getItem("isAdmin"),
          setIsAdmin: setIsAdmin,
          countryCode: sessionStorage.getItem("countryCode"),
          setCountryCode: setCountryCode,
          setSession: setSession
        }}
      >
        <Router>
          <Route exact path="/" component={Login} />
          <PrivateRoute
            exact
            path="/dashboard"
            component={() => (
              <Dashboard handleCountryCode={handleCountryCode} />
            )}
          />
        </Router>
      </AuthProvider>
    </Fragment>
  );
};

export default App;
