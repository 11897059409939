import React, { Fragment } from "react";
import { ResponsiveHeatMap } from "@nivo/heatmap";
import moment from "moment";
import { useAuth } from "../../context/auth";
import HeatmapTooltip from "../GraphTooltip/heatmapTooltip";

const HeatMapChart = props => {
  const { data } = props;
  const days = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
  let maxVal = 0;
  const { isAuthenticated, isAdmin, countryCode } = useAuth();

  let resultData = [];
  for (let i = 0; i < 24; i++) {
    resultData[i] = { ...resultData[i] };
    resultData[i].hour = i;
    for (let k = 0; k < 7; k++) {
      resultData[i][days[k]] = 0;
    }
  }

  const createTooltip = ({
    xKey, yKey, value, color
  }) => {
    return <HeatmapTooltip xKey={xKey} yKey={yKey} value={value} color={color} />;
  };

  data.body.map(el => {
    let day = moment(el._id.date).isoWeekday();
    day--;
    el.duration.map((value, index) => {
      resultData[index][days[day]] >= 0
        ? (resultData[index][days[day]] += value)
        : (resultData[index][days[day]] = 0);

      if (resultData[index][days[day]] > maxVal)
        maxVal = resultData[index][days[day]];
      return true;
    });
    return true;
  });

  resultData.map(data=>{
    data.hour =  data.hour.toString()
  })

  return (
    <Fragment>
      <ResponsiveHeatMap
        height={400}
        enableLabels={false}
        data={resultData}
        minValue={0}
        keys={days}
        indexBy="hour"
        margin={{ top: 0, right: 50, bottom: 50, left: 0 }}
        forceSquare={false}
        colors="blues"
        padding={2}
        axisBottom={{
          orient: "top",
          tickSize: 0,
          tickPadding: 5,
          tickRotation: 0,
          legend: "",
          legendOffset: 36
        }}
        axisLeft={null}
        axisTop={null}
        axisRight={{
          orient: "left",
          tickSize: 0,
          tickPadding: 5,
          tickRotation: 0,
          legend: "Hour",
          legendPosition: "middle",
          legendOffset: 40
        }}
        tooltip={({ xKey, yKey, value, color }) =>
          createTooltip({
            xKey, yKey, value, color
          })
        }
        isInteractive={isAdmin > 0 ? true : false}
        cellOpacity={1}
        cellBorderColor={{ from: "color", modifiers: [["darker", 0.4]] }}
        labelTextColor="#ffffff"
        animate={true}
        motionStiffness={80}
        motionDamping={9}
        hoverTarget="cell"
        cellHoverOthersOpacity={1}
      />
    </Fragment>
  );
};

export default HeatMapChart;
