import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import HoursAndMinutes from '../../lib/HoursAndMinutes'

const useStyles = makeStyles({
  tooltipTable: {
    fontSize: ".8rem"
  },
  label: {
    fontSize: ".7rem"
  },
  value: {
    fontWeight: 900
  }
});

const HeatmapTooltip = props => {
  const { xKey, yKey, value, color } = props;
  const classes = useStyles();
  return (
    <table className={classes.tooltipTable}>
      <tbody>
        <tr>
          <td colSpan="3">
            <strong>
              {xKey} @ {yKey}:00 - {yKey}:59
            </strong>
          </td>
        </tr>
        <tr>
          <td className={classes.label}>TOT. TIME PLAYED:</td>
          <td className={classes.value}>{HoursAndMinutes(value)}</td>
        </tr>
      </tbody>
    </table>
  );
};

export default HeatmapTooltip;
