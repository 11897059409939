import React, { Fragment, useState, useEffect, useRef } from "react";
import { useAsync } from "react-async";
import HeatMapChart from "./heatMapChart";
import MenuDate from "../MenuDate";
import moment from "moment";
import { API_GamesByDay } from "../../Api/gameByDay";

import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import LinearProgress from "@material-ui/core/LinearProgress";
import IconButton from "@material-ui/core/IconButton";
import RefreshIcon from "@material-ui/icons/Refresh";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  chartCardTitle: {
    padding: "15px 0",
    fontWeight:'bold'
  }
});

const HeatMap = props => {
  const classes = useStyles();
  
  const [range, setRange] = useState({
    from: moment()
      .subtract(7, "days")
      .toDate(),
    to: moment()
      .subtract(1, "days")
      .toDate(),
    fromVs: moment()
      .subtract(14, "days")
      .toDate()
  });

  useEffect(() => {
    run(
      moment(range.from)
        .format("YYYY-MM-DD")
        .toString(),
      moment(range.to)
        .format("YYYY-MM-DD")
        .toString()
    );
  }, [range]);

  const onDateChange = range => {
    setRange(range);
  };

  const { data, error, isLoading, run } = useAsync({
    // promiseFn: API_GamesByDay.promise,
    deferFn: API_GamesByDay.deferred,
    dateFrom: moment(range.from)
      .format("YYYY-MM-DD")
      .toString(),
    dateTo: moment(range.to)
      .format("YYYY-MM-DD")
      .toString()
  });

  return (
    <Fragment>
      <Typography className={classes.chartCardTitle}>
        When do your customers play the most?
      </Typography>
      <Card>
        <CardHeader
          title="Total played time divided into hours and days of the week"
          subheader="Find out when in the time of day and days of week your customers play more"
        />
        <CardContent style={{ height: 400 }}>
          {data ? <HeatMapChart data={data} /> : null}
        </CardContent>
        <CardActions>
          <MenuDate onDateChange={days => onDateChange(days)} />
          <IconButton
            onClick={() => {
              run(
                moment(range.from)
                  .format("YYYY-MM-DD")
                  .toString(),
                moment(range.to)
                  .format("YYYY-MM-DD")
                  .toString()
              );
            }}
            style={{ marginLeft: "auto" }}
          >
            <RefreshIcon />
          </IconButton>
        </CardActions>
        {isLoading ? <LinearProgress /> : null}
      </Card>
    </Fragment>
  );
};

export default HeatMap;
