import React from "react";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import TrendingDownIcon from "@material-ui/icons/TrendingDown";
import TrendingFlatIcon from "@material-ui/icons/TrendingFlat";

const calcTrend = (current, old) => {
  let trend = 0;
  if (current !== 0) {
    if (current > old) {
      trend = 100 - (old * 100) / current;
    } else {
      trend = -(100 - (current * 100) / old);
    }
    trend = parseInt(trend * 10) / 10;
  }

  if (trend > 0) {
    return (
      <span className="positiveTrend">
        <TrendingUpIcon fontSize={"small"} /> {trend}%
      </span>
    );
  } else if (trend === 0) {
    return (
      <span className="flatTrend">
        <TrendingFlatIcon fontSize={"small"} /> {trend}%
      </span>
    );
  } else {
    return (
      <span className="negativeTrend">
        <TrendingDownIcon fontSize={"small"} /> {trend}%
      </span>
    );
  }
};

export default calcTrend;
