const MinutesOrHours = time => {
  let hours,
    minutes,
    seconds = 0;
  if (Math.floor(time / 3600) === 0) {
    if (time === 0) {
      return 0 + " seconds";
    }
    minutes = Math.trunc(time / 60);
    seconds = Math.round((time / 60 - minutes) * 60);
    return minutes + " minutes " + seconds + " seconds";
  } else {
    hours = Math.trunc(time / 3600);
    minutes = Math.round((time / 3600 - hours) * 60);
    return hours + " hours " + minutes + " minutes";
  }
};

export default MinutesOrHours;
