import React, { Fragment } from "react";
import { DEVICETYPE_API } from "../../constants/api";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import LinearProgress from "@material-ui/core/LinearProgress";
import IconButton from "@material-ui/core/IconButton";
import RefreshIcon from "@material-ui/icons/Refresh";
import { useAsync } from "react-async";
import DeviceTypeChart from "./deviceTypeChart";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { TokenGenerator } from "../../lib/TokenGenerator";
 
const useStyles = makeStyles({
  chartCardTitle: {
    padding: "15px 0",
    fontWeight:'bold'
  }
});

const API_deviceType = async () =>
  await fetch(DEVICETYPE_API + "/" + sessionStorage.getItem("countryCode"),{headers:new Headers({'tokenHeader':TokenGenerator()})})
    .then(res => (res.ok ? res : Promise.reject(res)))
    .then(res => res.json());

const DeviceType = props => {
  const { data, error, isLoading, run } = useAsync({
    promiseFn: API_deviceType,
    deferFn: API_deviceType
  });
  
  const classes = useStyles();

  return (
    <Fragment>
      <Typography className={classes.chartCardTitle}>
      What devices are there in your network?
      </Typography>
      <Card>
        <CardHeader
          title="Type and number of devices"
          subheader="Check the composition of your network: total number of devices and the breakdown by type"
        />
        <CardContent style={{ height: 400 }}>
          {data ? <DeviceTypeChart data={data} /> : null}
        </CardContent>
        <CardActions>
          <IconButton onClick={run} style={{ marginLeft: "auto" }}>
            <RefreshIcon />
          </IconButton>
        </CardActions>
        {isLoading ? <LinearProgress /> : null}
      </Card>
    </Fragment>
  );
};

export default DeviceType;
